import { ALGOLIA_INDEX, algoliaSortOptions } from '~/lib/algolia';
import { UiState } from 'instantsearch.js';
import { createInstantSearchRouterNext } from 'react-instantsearch-router-nextjs';
import singletonRouter from 'next/router';
import type { RouterProps } from 'instantsearch.js/es/middlewares/createRouterMiddleware';

type RouteState = {
    refinementList?: { [key: string]: string[] };
    page?: number;
    range?: { [attribute: string]: string };
    sort?: string;
    toggle?: { [key: string]: boolean };
    query?: string;
};

export const routing = (requestUrl: string): RouterProps<UiState> => ({
    router: createInstantSearchRouterNext({
        singletonRouter,
        serverUrl: requestUrl,
        routerOptions: {
            cleanUrlOnDispose: true,
            createURL({ qsModule, location, routeState }) {
                const url = new URL(location.toString());

                // If there is an active search, we'll modify the URL.
                if (Object.keys(routeState).length) {
                    const currentParams = qsModule.parse(url.searchParams.toString());

                    // We'll merge the current query parameters with the routeState object.
                    const mergedParams = Object.assign({}, currentParams, routeState);

                    url.search = qsModule.stringify(mergedParams);
                } else {
                    // Otherwise, we'll remove the search parameter.
                    url.searchParams.delete(ALGOLIA_INDEX);
                }

                return url.toString();
            },
        },
    }),
    stateMapping: {
        stateToRoute(uiState: UiState): any {
            const indexUiState = uiState[ALGOLIA_INDEX] || {};

            const sort = algoliaSortOptions.find(
                (option) => option.value === uiState?.products?.sortBy,
            )?.label;

            /**
             * NOTE: The "page" search parameter persists on load and is kept as the initial state as a string.
             * To combat this, we only check for "page" values that are numbers.
             */
            const page =
                typeof uiState.products?.page === 'number' ? uiState.products.page : undefined;

            return {
                refinementList: indexUiState.refinementList,
                page,
                range: indexUiState.range,
                sort,
                toggle: indexUiState.toggle,
                // NOTE: `query` exists on both "configure" and "indexUiState".
                // We'll try and use both for reassurance.
                query: indexUiState.query || (indexUiState.configure as { query: string })?.query,
            };
        },
        routeToState(routeState: RouteState): UiState {
            const sortBy = algoliaSortOptions.find(
                (option) => option.label === routeState.sort,
            )?.value;

            return {
                [ALGOLIA_INDEX]: {
                    range: routeState.range,
                    query: routeState.query,
                    refinementList: routeState.refinementList,
                    toggle: routeState.toggle,
                },
                products: {
                    page: routeState.page,
                    sortBy,
                },
            };
        },
    },
});
